import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { RootState } from 'redux/types/store'
import { Loaders } from 'redux/reducers/loaders/types'
import { Alert } from 'antd'
import { FileName } from 'components/common/data-entry/FileUploadDropZone/DropZone/FileName'
import { Status } from 'components/common/data-entry/FileUploadDropZone/DropZone/Status'
import { DropZoneDescription } from 'components/common/data-entry/FileUploadDropZone/DropZone/Description'
import { getIsLoading } from 'redux/selectors/loaders'
import { getFileValidationError } from 'utils/audio-pipeline/file-upload/validation'
import { getFileUrl } from 'redux/selectors/audio-pipeline'
import uploadIcon from 'assets/icons/upload-icon.svg'
import styles from './styles.module.css'

interface Props {
  fileName?: string
  onClick?: () => void
  onDrop: (file: File | null) => void
  onRemoveFile?: () => void
}

export const FileDropZone: React.FC<Props> = ({
  fileName,
  onClick,
  onRemoveFile,
  onDrop
}) => {
  const [isDragEntered, setDragEntered] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const fileUrl = useSelector(getFileUrl)
  const isUploading = useSelector((state: RootState) => getIsLoading(state, Loaders.UploadFile))
  const isTranscribing = useSelector((state: RootState) => getIsLoading(state, Loaders.TranscriptionData))
  const isLoading = isUploading || isTranscribing
  const isProcessing = !fileUrl && isLoading
  const isDisabled = isLoading || fileUrl

  const handleDragOver = useCallback((e: any) => {
    e.preventDefault()
    setDragEntered(true)
  }, [])

  const handleDragLeave = useCallback(() => {
    setDragEntered(false)
  }, [])

  const handleDrop = useCallback((e: any) => {
    e.preventDefault()

    setError(null)
    setDragEntered(false)

    const items: DataTransferItem[] = Array.from(e.dataTransfer.items)
    const error = getFileValidationError(items)

    const [item] = items
    const file = !error ? item.getAsFile() : null

    if (error) {
      setError(error)
    }

    onDrop(file)
  }, [onDrop])

  useEffect(() => {
    if (fileName) {
      setError(null)
    }
  }, [fileName])

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.dragEntered]: isDragEntered,
        [styles.disabled]: isDisabled,
      })}
      onClick={onClick}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      <img src={uploadIcon} className={styles.icon} alt={uploadIcon} />
      <DropZoneDescription className={styles.description} />
      {fileName && <FileName
        name={fileName}
        isProcessing={isProcessing}
        onClose={onRemoveFile} />
      }
      <Status />
      {error && <Alert type='error' className={styles.error} message={error} banner />}
    </div>
  )
}