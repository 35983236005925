import React from 'react'
import { Alert } from 'antd'
import { useSelector } from 'react-redux'
import { getTranscriptionSearchValidationError } from 'redux/selectors/transcript-search'
import styles from './styles.module.css'

export const SearchValidationError: React.FC = () => {
  const error = useSelector(getTranscriptionSearchValidationError)

  if (!error) {
    return null
  }

  return (
    <Alert
      banner
      type='error'
      className={styles.wrapper}
      message={error}
    />
  )
}