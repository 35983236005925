import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import { TranscriptSearchMode } from 'redux/reducers/transcript-search/types'
import { SearchInput } from 'components/common/data-entry/SearchInput'
import { SearchControls } from 'components/audio-pipeline/TranscribedText/Transcript/SearchBar/SearchBarContent/SearchField/SearchControls'
import {
  getSearchText,
  getTranscriptionSearchMode
} from 'redux/selectors/transcript-search'
import {
  setReplaceSearchText,
  setSearchText
} from 'redux/actions/transcript-search'
import styles from './styles.module.css'

export const TranscriptSearchField: React.FC = () => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const mode = useSelector(getTranscriptionSearchMode)
  const searchText = useSelector(getSearchText)
  const placeholder = formatMessage({
    id: 'component.transcript-search.search-input.placeholder'
  })

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (mode === TranscriptSearchMode.SearchAndReplace) {
      const text = e.target.value.trim()

      return dispatch(setReplaceSearchText(text))
    }

    dispatch(setSearchText(e.target.value))
  }, [mode, dispatch])

  return (
    <div className={styles.wrapper}>
      <SearchInput
        value={searchText}
        className={classNames({
          [styles.searchAndReplace]: mode === TranscriptSearchMode.SearchAndReplace
        })}
        inputClassName={styles.input}
        placeholder={placeholder}
        onChange={handleChange}
      />
      {searchText && <SearchControls />}
    </div>
  )
}