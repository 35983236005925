import React, { useCallback } from 'react'
import { useNavigate } from 'react-router'
import { Outlet } from 'react-router-dom'
import { Security } from '@okta/okta-react'
import { Routes } from 'routes/constants'
import { oktaAuth } from 'utils/auth/auth-client'

export const AuthLayout: React.FC = () => {
  const navigate = useNavigate()

  const handleAuthRequired = useCallback(() => {
    navigate(Routes.Login)
  }, [navigate])

  const restoreOriginalUri = useCallback(() =>
    navigate(Routes.Index, { replace: true }), [navigate])

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={handleAuthRequired}
      restoreOriginalUri={restoreOriginalUri}
    >
      <Outlet />
    </Security>
  )
}