import React from 'react'
import { Section } from 'components/common/data-display/Section'
import { LanguageSelect } from 'components/audio-pipeline/FileUpload/LanguageSelect'
import { TranscribeButton } from 'components/audio-pipeline/FileUpload/TranscribeButton'
import { FileUploadField } from 'components/audio-pipeline/FileUpload/FileUploadField'
import { FileUrlInput } from 'components/audio-pipeline/FileUpload/FileUrlInput'
import config from 'config'

export const FileUpload: React.FC = () => (
  <Section>
    {config.uploadEnabled && <FileUploadField />}
    <FileUrlInput />
    <LanguageSelect />
    <TranscribeButton />
  </Section>
)