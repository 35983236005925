import messages from 'i18n/en_US'
import { createIntl, createIntlCache } from 'react-intl'

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache()

// Create the `intl` object
export const intl = createIntl(
  {
    // Locale of the application
    locale: 'en',
    // Locale of the fallback defaultMessage
    defaultLocale: 'en',
    messages,
  },
  cache
)