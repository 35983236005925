import React from 'react'
import classNames from 'classnames'
import { Typography } from 'antd'
import { Loader } from 'components/common/feedback/Loader'
import styles from './styles.module.css'

const { Text } = Typography

interface Props {
  size?: number
  className?: string
  label?: string
}

export const PipelineLoader: React.FC<Props> = ({
  label,
  className
}) => {
  return (
    <div
      className={classNames(styles.wrapper, className)}
    >
      <Loader className={styles.loader}/>
      {label && <Text className={styles.label}>{label}</Text>}
    </div>
  )
}