import React, { MouseEventHandler } from 'react'
import classNames from 'classnames'
import { Button as BaseButton } from 'antd'
import { ButtonType } from 'antd/es/button/buttonHelpers'
import styles from './styles.module.css'

interface Props {
  text?: string
  type?: ButtonType
  className?: string
  disabled?: boolean
  loading?: boolean
  danger?: boolean
  icon?: React.ReactNode
  onClick?: MouseEventHandler
}

export const Button: React.FC<Props> = ({
  text,
  type,
  disabled,
  loading,
  danger,
  icon,
  className,
  onClick
}) => (
  <BaseButton
    type={type}
    icon={icon}
    loading={loading}
    disabled={disabled}
    danger={danger}
    className={classNames(styles.wrapper, className)}
    onClick={onClick}
  >
    {text}
  </BaseButton>
)