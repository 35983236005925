import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import omit from 'lodash/omit'
import { ReducersType } from 'redux/reducers/types'
import { AudioPipelineInitialState } from 'redux/reducers/audio-pipeline/constants'
import {
  ConsumeTranscriptionPayload,
  EditWordPayload,
  SetEditedWordPayload,
  SetFileUploadProgressPayload,
  SetFileUrlPayload,
  SetPlayerCurrentTimePayload,
  SetPlayerSelectedWordIdPayload,
  SetSelectedLanguagePayload,
  SetTextValuePayload,
  SetSpeakerNamesPayload,
  UploadFilePayload,
  SetEditedWordTextPayload
} from 'redux/actions/audio-pipeline/types'

export const audioPipeline = createSlice({
  name: ReducersType.AudioPipeline,
  initialState: AudioPipelineInitialState,
  reducers: {
    setSelectedLanguage: (state, { payload: { language } }: PayloadAction<SetSelectedLanguagePayload>) => {
      state.language = language
    },
    setFileUrl: (state, { payload: { value } }: PayloadAction<SetFileUrlPayload>) => {
      state.fileUrl = value
    },
    consumeTranscription: (state, { payload: { transcription } }: PayloadAction<ConsumeTranscriptionPayload>) => {
      state.transcription = transcription
    },
    setSummarisedText: (state, { payload: { text } }: PayloadAction<SetTextValuePayload>) => {
      state.summarisedText = text
    },
    setHeadlineTailoringText: (state, { payload: { text } }: PayloadAction<SetTextValuePayload>) => {
      state.headlineTailoringText = text
    },
    setGeneratedHeadlineText: (state, { payload: { text } }: PayloadAction<SetTextValuePayload>) => {
      state.generatedHeadlineText = text
    },
    setGeneratedArticleText: (state, { payload: { text } }: PayloadAction<SetTextValuePayload>) => {
      state.generatedArticleText = text
    },
    setSEOTagsText: (state, { payload: { text } }: PayloadAction<SetTextValuePayload>) => {
      state.SEOTagsText = text
    },
    setSpeakerNamesFieldText: (state, { payload: { text } }: PayloadAction<SetTextValuePayload>) => {
      state.speakerNamesFieldText = text
    },
    setSpeakerNames: (state, { payload: { speakerNames } }: PayloadAction<SetSpeakerNamesPayload>) => {
      state.speakerNames = speakerNames
    },
    setFileUploadProgress: (state, { payload: { percent } }: PayloadAction<SetFileUploadProgressPayload>) => {
      state.fileUploadProgress = percent
    },
    setSelectedFile: (state, { payload: { file } }: PayloadAction<UploadFilePayload>) => {
      state.file = file
    },
    clearInputFields: (state) => ({
      ...state,
      transcription: AudioPipelineInitialState.transcription,
      summarisedText: AudioPipelineInitialState.summarisedText,
      headlineTailoringText: AudioPipelineInitialState.headlineTailoringText,
      generatedHeadlineText: AudioPipelineInitialState.generatedHeadlineText,
      generatedArticleText: AudioPipelineInitialState.generatedArticleText,
      SEOTagsText: AudioPipelineInitialState.SEOTagsText,
      speakerNamesFieldText: AudioPipelineInitialState.speakerNamesFieldText,
      speakerNames: AudioPipelineInitialState.speakerNames
    }),
    setPlayerCurrentTime: (state, { payload: { timestamp } }: PayloadAction<SetPlayerCurrentTimePayload>) => {
      state.playerCurrentTime = timestamp
    },
    setPlayerSelectedWordId: (state, { payload: { id } }: PayloadAction<SetPlayerSelectedWordIdPayload>) => {
      state.playerCurrentWordId = id
    },
    startPlayback: (state) => {
      state.isPlaybackStarted = true
    },
    stopPlayback: (state) => {
      state.isPlaybackStarted = false
    },
    setEditedWord: (state, { payload: { id, text, startTime, endTime } }: PayloadAction<SetEditedWordPayload>) => {
      state.editedWordsById[id] = { id, text, startTime, endTime }
    },
    revertEditedWord: (state, { payload: { id } }: PayloadAction<EditWordPayload>) => {
      state.editedWordsById = omit(state.editedWordsById, id)
    },
    setEditedWordId: (state, { payload: { id } }: PayloadAction<EditWordPayload>) => {
      state.editedWordId = id
    },
    setEditedWordText: (state, { payload: { text } }: PayloadAction<SetEditedWordTextPayload>) => {
      state.editedWordText = text
    }
  }
})