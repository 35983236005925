import React from 'react'
import { useSelector } from 'react-redux'
import { Typography } from 'antd'
import {
  getSelectedSearchMatchIndex,
  getTranscriptionSearchMatchGroupIds
} from 'redux/selectors/transcript-search'
import { constrain } from 'utils/common/other'
import styles from './styles.module.css'

const { Text } = Typography

export const MatchCount: React.FC = () => {
  const matchGroupIds = useSelector(getTranscriptionSearchMatchGroupIds)
  const currentSelectedMatchIndex = useSelector(getSelectedSearchMatchIndex)
  const currentIndex = constrain({
    min: 0,
    max: matchGroupIds.length,
    value: currentSelectedMatchIndex + 1
  })
  const text = `${currentIndex}/${matchGroupIds.length}`

  return (
    <Text className={styles.wrapper}>{text}</Text>
  )
}