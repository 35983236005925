export enum TranscriptSearchMode {
  Search = 'search',
  SearchAndReplace = 'search-and-replace'
}

export interface TranscriptionSearchMatchWord {
  id: string
  groupId: string
  text: string
  startTime: number
  endTime: number
}

export interface TranscriptionSearchMatchGroup {
  id: string
  words: TranscriptionSearchMatchWord[]
}

export interface TranscriptSearchStateType {
  text: string
  selectedMatchId: string | null
  mode: TranscriptSearchMode | null
  replaceSearchText: string
  replaceText: string
  error: string | null
}