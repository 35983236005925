import React from 'react'
import { Logo } from 'components/layouts/common/Header/Logo'
import { UserInfo } from 'components/layouts/common/Header/UserInfo'
import styles from './styles.module.css'

export const Header: React.FC = () => (
  <div className={styles.wrapper}>
    <UserInfo />
    <Logo />
  </div>
)