import { ReplaceSpeakerNamesPayload } from 'utils/audio-pipeline/types'
import { AMERICANIZE_REGEXES, SPEAKER_NAME_REGEX } from 'utils/audio-pipeline/constants'

export const replaceSpeakerNames = ({ text, names }: ReplaceSpeakerNamesPayload) =>
  text.replace(SPEAKER_NAME_REGEX, (match) => {
    const [, index] = match.split(/\s/)
    const nameIndex = index ? parseInt(index) - 1 : 0

    return names[nameIndex] || match
  })

export const capitalize = (word: string) => {
  const [first, ...rest]= word.split('')

    return [first.toUpperCase(), ...rest].join('')
}

export const americanize = (text: string) =>
  AMERICANIZE_REGEXES.reduce((acc, tuple ) => {
    const [regex, us] = tuple
    return acc.replace(regex, (match) => {
      const [firstEnLetter]= match.split('')
      const isFirstLetterCapital = firstEnLetter.toUpperCase() === firstEnLetter

      return isFirstLetterCapital ? capitalize(us) : us
    })
  }, text)
