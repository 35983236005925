import { createBrowserRouter } from 'react-router-dom'
import { PrivateRoute } from 'components/common/auth/PrivateRoute'
import Index from 'routes/Index'
import { Routes } from 'routes/constants'
import { Login } from 'routes/Login'
import { AuthLayout } from 'components/layouts/auth/AuthLayout'

export const router = createBrowserRouter([
  {
    element: <AuthLayout />,
    children: [
      {
        path: Routes.Index,
        element: (
          <PrivateRoute>
            <Index />
          </PrivateRoute>
        )
      },
      {
        path: Routes.Login,
        element: <Login />
      }
    ]
  }
])
