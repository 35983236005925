import React, { useCallback } from 'react'
import { CloseCircleFilled } from '@ant-design/icons'
import { Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { TranscriptSearchMode } from 'redux/reducers/transcript-search/types'
import { getTranscriptionSearchMode } from 'redux/selectors/transcript-search'
import {
  setReplaceSearchText,
  setSearchText
} from 'redux/actions/transcript-search'
import styles from './styles.module.css'

export const ClearButton: React.FC = () => {
  const dispatch = useDispatch()
  const mode = useSelector(getTranscriptionSearchMode)

  const handleClear = useCallback(() => {
    if (mode === TranscriptSearchMode.SearchAndReplace) {
      return dispatch(setReplaceSearchText(''))
    }

    dispatch(setSearchText(''))
  }, [dispatch, mode])

  return (
    <Button
      type='text'
      className={styles.wrapper}
      icon={<CloseCircleFilled className={styles.icon} />}
      onClick={handleClear}
    />
  )
}