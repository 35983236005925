import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Loaders } from 'redux/reducers/loaders/types'
import { RootState } from 'redux/types/store'
import { Input } from 'antd'
import { PipelineLoader } from 'components/common/feedback/PipelineLoader'
import { getFileUrl, getSelectedFile } from 'redux/selectors/audio-pipeline'
import { getIsLoading } from 'redux/selectors/loaders'
import { setFileUrl } from 'redux/actions/audio-pipeline'
import styles from './styles.module.css'

export const FileUrlInput: React.FC = () => {
  const dispatch = useDispatch()
  const fileUrl = useSelector(getFileUrl)
  const file = useSelector(getSelectedFile)
  const isLoading = useSelector((state: RootState) => getIsLoading(state, Loaders.TranscriptionData))
  const { formatMessage } = useIntl()
  const placeholder = formatMessage({
    id: 'component.audio-pipeline.file-url-input.placeholder'
  })
  const loaderLabel = formatMessage({
    id: 'component.audio-pipeline.transcribe-processing.text'
  })
  const isProcessing = fileUrl && isLoading

  const handleChange = useCallback((
      e: React.ChangeEvent<HTMLInputElement>
    ) => dispatch(setFileUrl(e.target.value)),
    [dispatch])

  return (
    <div className={styles.wrapper}>
      {isProcessing && <PipelineLoader className={styles.loader} label={loaderLabel} />}
      <Input
        allowClear
        disabled={isLoading || !!file}
        value={fileUrl}
        placeholder={placeholder}
        onChange={handleChange}
        rootClassName={styles.wrapper}
      />
    </div>
  )
}