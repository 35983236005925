import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TranscriptSearchMode } from 'redux/reducers/transcript-search/types'
import { CloseCircleFilled } from '@ant-design/icons'
import {
  TranscriptSearchField
} from 'components/audio-pipeline/TranscribedText/Transcript/SearchBar/SearchBarContent/SearchField'
import {
  TranscriptReplaceField
} from 'components/audio-pipeline/TranscribedText/Transcript/SearchBar/SearchBarContent/ReplaceField'
import { getTranscriptionSearchMode } from 'redux/selectors/transcript-search'
import { resetState } from 'redux/actions/transcript-search'
import styles from './styles.module.css'
import {
  SearchValidationError
} from 'components/audio-pipeline/TranscribedText/Transcript/SearchBar/SearchValidationError'

export const SearchBarContent: React.FC = () => {
  const dispatch = useDispatch()
  const mode = useSelector(getTranscriptionSearchMode)

  const handleCloseSearch = useCallback(() => {
    dispatch(resetState())
  }, [dispatch])

  return (
    <div className={styles.wrapper}>
      <TranscriptSearchField />
      {mode === TranscriptSearchMode.SearchAndReplace
        && <TranscriptReplaceField />
      }
      <CloseCircleFilled
        className={styles.closeButton}
        onClick={handleCloseSearch}
      />
      {mode === TranscriptSearchMode.SearchAndReplace
        && <SearchValidationError />
      }
    </div>
  )
}