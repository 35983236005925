import { RawTranscriptionSpeakerMonolog } from 'components/audio-pipeline/TranscribedText/Transcript/types'

export enum AudioPipelineSections {
  FileUpload = 'file-upload',
  TranscribedText = 'transcribed-text',
  SummarisedText = 'summarised-text',
  Headline = 'headline',
  ArticleGeneration = 'article-generation',
  SEOTags = 'seo-tags'
}

export enum AudioPipelineTranscriptionResponseStatus {
  Success = 'SUCCESS',
  Error = 'ERROR',
}

export interface AudioPipelineTranscriptionResponse {
  id: string
  status: AudioPipelineTranscriptionResponseStatus
  transcription: RawTranscriptionSpeakerMonolog[]
}