import { OptionType } from 'components/common/data-entry/Select/types'
import { intl } from 'utils/common/intl'
import { LanguageSelectOptionIds } from 'components/audio-pipeline/FileUpload/LanguageSelect/types'

export const LANGUAGE_SELECT_OPTIONS: OptionType<LanguageSelectOptionIds>[] = Object
  .values(LanguageSelectOptionIds)
  .map(value => ({
    value,
    label: intl.formatMessage({
      id: `component.audio-pipeline.language-select.option.${value}`
    })
  }))