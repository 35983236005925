import React, { useCallback } from 'react'
import { AUDIO_PIPELINE_SECTIONS } from 'constants/audio-pipeline'
import { AudioPipelineSections } from 'constants/audio-pipeline/types'
import { FileUpload } from 'components/audio-pipeline/FileUpload'
import { TranscribedText } from 'components/audio-pipeline/TranscribedText'
import { SummarisedText } from 'components/audio-pipeline/SummarisedText'
import { Headline } from 'components/audio-pipeline/Headline'
import { ArticleGeneration } from 'components/audio-pipeline/ArticleGeneration'
import { SEOTags } from 'components/audio-pipeline/SEOTags'

export const AudioPipeline: React.FC = () => {
  const renderSection = useCallback((id: AudioPipelineSections) => {
    switch (id) {
      case AudioPipelineSections.FileUpload:
        return <FileUpload key={id} />
      case AudioPipelineSections.TranscribedText:
        return <TranscribedText key={id} />
      case AudioPipelineSections.SummarisedText:
        return <SummarisedText key={id} />
      case AudioPipelineSections.Headline:
        return <Headline key={id} />
      case AudioPipelineSections.ArticleGeneration:
        return <ArticleGeneration key={id} />
      case AudioPipelineSections.SEOTags:
        return <SEOTags key={id} />
      default:
        return null
    }
  }, [])

  return (
    <div>{AUDIO_PIPELINE_SECTIONS.map(renderSection)}</div>
  )
}