import { AMERICANISMS } from 'redux/sagas/audio-pipeline/americanisms'

export const SPEAKER_NAME_REGEX = /Speaker\s[0-9]+/g

export const AMERICANIZE_REGEXES: [RegExp, string][] = Object
  .entries(AMERICANISMS)
  .map((tuple) => {
  const [en, us] = tuple

  return [new RegExp(en, 'gi'), us]
})