import React, { useCallback, useEffect } from 'react'
import { Audio } from 'components/common/media/Audio'
import { useDispatch, useSelector } from 'react-redux'
import {
  setPlayerCurrentTime,
  startPlayback,
  stopPlayback
} from 'redux/actions/audio-pipeline'
import {
  getIsPlaybackStarted,
  getPlayerCurrentWordId,
  getSelectedFile
} from 'redux/selectors/audio-pipeline'

interface Props {
  id?: string
  src: string
}

export const AudioPlayer: React.FC<Props> = ({
  id,
  src
}) => {
  const dispatch = useDispatch()
  const currentWordId = useSelector(getPlayerCurrentWordId)
  const selectedFile = useSelector(getSelectedFile)
  const isPlaybackStarted = useSelector(getIsPlaybackStarted)
  const startTime = currentWordId && currentWordId.split('-')[1]
  const time = Number(startTime) / 1000

  const handleTimeUpdate = useCallback((timestamp: number) => {
    if (!isPlaybackStarted) {
      dispatch(setPlayerCurrentTime(timestamp))
    }
  }, [dispatch, isPlaybackStarted])

  const handlePlay = useCallback(() => {
    dispatch(startPlayback())
  }, [dispatch])

  const handlePause = useCallback(() => {
    dispatch(stopPlayback())
  }, [dispatch])

  useEffect(() => {
    // Reset playback when the selected file is changed
    dispatch(setPlayerCurrentTime(0))
    handlePause()
  }, [dispatch, handlePause, selectedFile])

  return (
    <Audio
      id={id}
      src={src}
      currentTime={time}
      onPlay={handlePlay}
      onPause={handlePause}
      onTimeUpdate={handleTimeUpdate}
    />
  )
}