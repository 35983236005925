import React, { useCallback, useEffect } from 'react'
import { notification } from 'antd'
import { NotificationItemType as NotificationPropType } from 'redux/reducers/notifications/types'
import { useDispatch } from 'react-redux'
import { removeNotification } from 'redux/actions/notifications'
import styles from './styles.module.css'

type Props = NotificationPropType

export const Notification: React.FC<Props> = ({
  id,
  type,
  message,
  description,
  duration,
  placement
}) => {
  const dispatch = useDispatch()

  const handleClose = useCallback(() => {
    dispatch(removeNotification(id))
    notification.destroy(id)
  }, [id, dispatch])

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null

    notification.open({
      key: id,
      type,
      message,
      description,
      duration,
      placement,
      className: styles.wrapper,
      onClose: handleClose
    })

    if (duration) {
      timeout = setTimeout(handleClose, duration)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }

      handleClose()
    }
  }, [
    id,
    type,
    message,
    description,
    duration,
    placement,
    handleClose,
    dispatch
  ])

  return null
}
