import React, { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Divider } from 'antd'
import { AUDIO_PLAYER_ID } from 'components/audio-pipeline/FileUpload/FileUploadField/constants'
import { Title } from 'components/common/general/Title'
import { FileUploadDropZone } from 'components/common/data-entry/FileUploadDropZone'
import { AudioPlayer } from 'components/audio-pipeline/FileUpload/AudioPlayer'
import { setFileUploadProgress } from 'redux/actions/audio-pipeline'
import { setSelectedFile } from 'redux/actions/audio-pipeline'
import { getSelectedFile } from 'redux/selectors/audio-pipeline'
import styles from './styles.module.css'

const reader = new FileReader()

export const FileUploadField: React.FC = () => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const [src, setSrc] = useState<string | null>(null)
  const selectedFile = useSelector(getSelectedFile)
  const title = formatMessage({
    id: 'component.audio-pipeline.file-upload.title'
  })
  const dividerLabel = formatMessage({
    id: 'component.common.label.or'
  })

  const handleSelectFile = useCallback((file: File | null) => {
    dispatch(setSelectedFile(file))
  }, [dispatch])

  const handleRemoveFile = useCallback(() => {
    dispatch(setSelectedFile(null))
  }, [dispatch])

  const handleLoadFile = useCallback(() => {
    const src = reader.result && reader.result.toString()

    setSrc(src)
  }, [])

  useEffect(() => {
    reader.addEventListener('load', handleLoadFile)

    return () => {
      reader.removeEventListener('load', handleLoadFile)
    }
  }, [handleLoadFile])

  useEffect(() => {
    if (!selectedFile && src) {
      setSrc(null)
      reader.abort()
    }

    if (selectedFile) {
      reader.readAsDataURL(selectedFile)
    }

    dispatch(setFileUploadProgress(0))
  }, [dispatch, src, selectedFile])

  return (
    <div className={styles.wrapper}>
      <Title text={title} />
      <FileUploadDropZone
        file={selectedFile}
        onSelectFile={handleSelectFile}
        onRemoveFile={handleRemoveFile}
      />
      {src && <AudioPlayer id={AUDIO_PLAYER_ID} src={src} />}
      <Divider>{dividerLabel}</Divider>
    </div>
  )
}