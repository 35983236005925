import React from 'react'
import classNames from 'classnames'
import { OptionType, OptionValueType } from 'components/common/data-entry/Select/types'
import { Select as BaseSelect, Typography } from 'antd'
import styles from './styles.module.css'

const { Text } = Typography

interface Props<T extends OptionValueType> {
  value?: T
  label?: string
  disabled?: boolean
  className?: string
  options?: OptionType<T>[]
  onChange?: (value: T, option: OptionType<T> | OptionType<T>[]) => void
}

export const Select = <T extends OptionValueType>({
  label,
  className,
  value,
  disabled,
  options,
  onChange
}: Props<T>) => (
  <div className={classNames(styles.wrapper, className)}>
    {label && <Text className={styles.label}>{label}</Text>}
    <BaseSelect
      disabled={disabled}
      className={styles.select}
      value={value}
      options={options}
      onChange={onChange}
    />
  </div>
)