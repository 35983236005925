import home from 'i18n/en_US/home'
import common from 'i18n/en_US/common'
import auth from 'i18n/en_US/auth'
import audioPipeline from 'i18n/en_US/audio-pipeline'
import transcriptSearch from 'i18n/en_US/transcript-search'
import error from 'i18n/en_US/error'

const messages = {
  ...home,
  ...common,
  ...auth,
  ...audioPipeline,
  ...error,
  ...transcriptSearch
}

export default messages
