import {
  RawTranscriptionSpeakerMonolog,
  RawTranscriptionWord,
  TranscriptionSpeakerMonolog,
  TranscriptionWord
} from 'components/audio-pipeline/TranscribedText/Transcript/types'
import { LanguageSelectOptionIds } from 'components/audio-pipeline/FileUpload/LanguageSelect/types'
import { americanize } from 'utils/audio-pipeline/index'

export const mapTranscriptionWords = ({
  words,
  lang
}: { words: RawTranscriptionWord[], lang: LanguageSelectOptionIds }): TranscriptionWord[] =>
  words.map(({ word, start_time, end_time }) => ({
    id: `${word}-${start_time}-${end_time}`,
    startTime: start_time,
    endTime: end_time,
    text: lang === LanguageSelectOptionIds.en_US
      ? americanize(word)
      : word
  }))

export const mapTranscriptionResponse = ({
  transcription,
  lang
}: {
  transcription: RawTranscriptionSpeakerMonolog[],
  lang: LanguageSelectOptionIds
}): TranscriptionSpeakerMonolog[] =>
  transcription.map(({ speaker, start_time, end_time, paragraphs }) => ({
    speaker,
    startTime: start_time,
    endTime: end_time,
    paragraphs: paragraphs.map(({ text, start_time, end_time, word_timestamps }) => ({
      text,
      startTime: start_time,
      endTime: end_time,
      speaker,
      wordTimestamps: mapTranscriptionWords({ words: word_timestamps, lang })
    }))
  }))