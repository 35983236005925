export enum NotificationType {
  Error = 'error',
  Success = 'success',
  Info = 'info'
}

export enum NotificationPlacement {
  TopLeft = 'topLeft',
  TopRight = 'topRight',
  BottomLeft = 'bottomLeft',
  BottomRight = 'bottomRight'
}

export interface NotificationItemType {
  id: string
  type: NotificationType
  message: string
  description?: string
  placement?: NotificationPlacement
  duration?: number
}

export interface NotificationsStateType {
  items: NotificationItemType[]
}
