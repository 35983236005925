import { intl } from 'utils/common/intl'

const isAudioFile = (type: string) => type.match(/audio\/*|video\/mp4/)

export const getFileValidationError = (items: DataTransferItem[]): string | null => {
  const [item] = items
  const file = item.getAsFile()
  const isValidFormat = file && isAudioFile(file.type)

  if (item.kind !== 'file' || !isValidFormat) {
    return intl.formatMessage({
      id: 'component.error.file.invalid-format'
    })
  }

  if (items.length > 1) {
    return intl.formatMessage({
      id: 'component.error.file.multiselect'
    })
  }

  return null
}