import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MatchCount } from 'components/audio-pipeline/TranscribedText/Transcript/SearchBar/SearchBarContent/SearchField/SearchControls/MatchCount'
import { PrevNextControls } from 'components/audio-pipeline/TranscribedText/Transcript/SearchBar/SearchBarContent/SearchField/SearchControls/PrevNextControls'
import { ClearButton } from 'components/audio-pipeline/TranscribedText/Transcript/SearchBar/SearchBarContent/SearchField/SearchControls/ClearButton'
import { setSearchSelectedMatchId } from 'redux/actions/transcript-search'
import {
  getTranscriptionSearchMatchGroupIds,
  getTranscriptionSearchSelectedMatchId
} from 'redux/selectors/transcript-search'
import styles from './styles.module.css'

export const SearchControls: React.FC = () => {
  const dispatch = useDispatch()
  const selectedMatchId = useSelector(getTranscriptionSearchSelectedMatchId)
  const matchGroupIds = useSelector(getTranscriptionSearchMatchGroupIds)
  const isSelectedMatchInGroupIds = selectedMatchId && matchGroupIds.includes(selectedMatchId)

  useEffect(() => {
    const [firstGroupId = null] = matchGroupIds

    if (!isSelectedMatchInGroupIds || !selectedMatchId) {
      dispatch(setSearchSelectedMatchId(firstGroupId))
    }
  }, [dispatch, matchGroupIds, isSelectedMatchInGroupIds, selectedMatchId])

  return (
    <div className={styles.wrapper}>
      <MatchCount />
      <PrevNextControls />
      <ClearButton />
    </div>
  )
}