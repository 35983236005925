import React from 'react'
import { Typography } from 'antd'
import styles from './styles.module.css'

const { Title: BaseTitle } = Typography

interface Props {
  text: string
}

export const Title: React.FC<Props> = ({
  text
}) => (
  <BaseTitle className={styles.wrapper}>{text}</BaseTitle>
)