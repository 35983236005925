import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TranscriptSearchModeSwitch } from 'components/audio-pipeline/TranscribedText/Transcript/SearchBar/SearchModeSwitch'
import { SearchBarContent } from 'components/audio-pipeline/TranscribedText/Transcript/SearchBar/SearchBarContent'
import {
  setSearchSelectedMatchId,
} from 'redux/actions/transcript-search'
import {
  getSearchText,
  getTranscriptionSearchMode
} from 'redux/selectors/transcript-search'
import styles from './styles.module.css'

export const TranscriptionSearchBar: React.FC = () => {
  const dispatch = useDispatch()
  const mode = useSelector(getTranscriptionSearchMode)
  const searchText = useSelector(getSearchText)

  useEffect(() => {
    if (!searchText) {
      dispatch(setSearchSelectedMatchId(null))
    }
  }, [dispatch, searchText])

  return (
    <div className={styles.wrapper}>
      <TranscriptSearchModeSwitch />
      {mode && <SearchBarContent />}
    </div>
  )
}