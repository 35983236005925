import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Colors } from 'constants/common/other/colors'
import { Tooltip } from 'antd'
import { WordTooltipContent } from 'components/audio-pipeline/TranscribedText/Transcript/WordTooltipContent'
import {
  getEditedWordId,
  getPlayerCurrentWordId,
} from 'redux/selectors/audio-pipeline'
import { setPlayerSelectedWordId } from 'redux/actions/audio-pipeline'
import {
  TranscriptWordTextContent
} from 'components/audio-pipeline/TranscribedText/Transcript/TranscriptWord/WordTextContent'

interface Props {
  id: string
  text: string
  startTime: number
  endTime: number
  isPassed?: boolean
  isActive?: boolean
}

export const TranscriptWord: React.FC<Props> = React.memo(({
  id,
  text,
  isPassed,
  isActive,
  startTime,
  endTime
}) => {
  const dispatch = useDispatch()
  const currentWordId = useSelector(getPlayerCurrentWordId)
  const editedWordId = useSelector(getEditedWordId)
  const isEditing = id === editedWordId

  useEffect(() => {
    if (isActive && currentWordId) {
      dispatch(setPlayerSelectedWordId(null))
    }
  }, [isActive, currentWordId, dispatch])

  const isOpen = editedWordId ? isEditing : undefined

  return (
    <Tooltip
      open={isOpen}
      destroyTooltipOnHide
      color={Colors.white}
      title={
        <WordTooltipContent
          id={id}
          text={text}
          startTime={startTime}
          endTime={endTime}
        />
      }
    >
      <>
        <TranscriptWordTextContent
          id={id}
          text={text}
          isPassed={isPassed}
          isActive={isActive}
        />
      </>
    </Tooltip>
  )
})