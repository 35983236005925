import React, { useCallback, useEffect, useState } from 'react'

export interface Props {
  text: string
  patterns: string[]
  className?: string
  dataCy?: string
  onMatch({
    text,
    index
  }: {
    text: string
    index: number
  }): React.ReactElement | string
}

export const Componentify: React.FC<Props> = ({
  text,
  patterns,
  className,
  dataCy,
  onMatch
}) => {
  const [regexp, setRegex] = useState<RegExp | null>(null)

  const setRegexPattern = useCallback(() => {
    const filteredPatterns = patterns.filter(
      (val?: string | null) => val !== null && val !== undefined && val !== ''
    )

    if (!filteredPatterns.length) {
      setRegex(null)
    }

    const pattern = filteredPatterns
      .map((val) =>
        val.toString().replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&')
      )
      .join('|')

    setRegex(new RegExp(pattern, 'gi'))
  }, [patterns])

  const markMatches = useCallback(() => {
    if (!regexp) {
      return text
    }

    return text.replace(
      regexp,
      (match) => `__match__${match}__match__`
    )
  }, [text, regexp])

  const renderText = useCallback(() => {
    const formattedText = markMatches()

    if (!regexp) {
      return text
    }

    return formattedText
      .split('__match__')
      .map((text: string, index: number) =>
        regexp.test(text) ? onMatch({ text, index }) : text
      )
  }, [regexp, text, markMatches, onMatch])

  useEffect(() => {
    setRegexPattern()
  }, [setRegexPattern])

  return (
    <div
      className={className}
      data-cy={dataCy}
    >
      {renderText()}
    </div>
  )
}