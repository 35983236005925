import React from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import { DEFAULT_ROWS_COUNT } from 'components/common/data-entry/TextArea/constants'
import { Input, Typography } from 'antd'
import { Button } from 'components/common/general/Button'
import { Title } from 'components/common/general/Title'
import styles from './styles.module.css'

const { Text } = Typography
const { TextArea: BaseTextArea } = Input

interface Props {
  value?: string
  title?: string
  description?: string
  placeholder?: string
  rows?: number
  shouldRenderSubmitButton?: boolean
  isSubmitDisabled?: boolean
  isLoading?: boolean
  readOnly?: boolean
  submitButtonText?: string
  submitButtonClassName?: string
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  onSubmit?: () => void
}

export const TextArea: React.FC<Props> = ({
  title,
  value,
  description,
  placeholder,
  submitButtonText,
  submitButtonClassName,
  isSubmitDisabled,
  isLoading,
  readOnly,
  shouldRenderSubmitButton,
  onChange,
  onSubmit,
  rows = DEFAULT_ROWS_COUNT
}) => {
  const { formatMessage } = useIntl()
  const submitText = submitButtonText || formatMessage({
    id: 'component.common.submit.text'
  })

  return (
    <div className={styles.wrapper}>
      {title && <Title text={title} />}
      <BaseTextArea
        readOnly={readOnly}
        className={styles.textarea}
        rows={rows}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
      {description && <Text className={styles.description}>{description}</Text>}
      {shouldRenderSubmitButton && (
        <Button
          className={classNames(styles.submit, submitButtonClassName)}
          text={submitText}
          disabled={isSubmitDisabled}
          loading={isLoading}
          onClick={onSubmit}
        />
      )}
    </div>
  )
}