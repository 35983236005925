import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Button, Typography } from 'antd'
import { revertEditedWord } from 'redux/actions/audio-pipeline'
import styles from './styles.module.css'

const { Text } = Typography

interface Props {
  id: string
  text: string
}

export const InitialWordData: React.FC<Props> = ({
  id,
  text
}) => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const buttonText = formatMessage({
    id: 'component.audio-pipeline.transcript.edit.undo'
  })
  const initialTextLabel = formatMessage({
    id: 'component.audio-pipeline.transcript.edit.initial'
  })

  const handleClick = useCallback(() => {
    dispatch(revertEditedWord(id))
  }, [id, dispatch])

  return (
    <div className={styles.wrapper}>
      <Text>{initialTextLabel}</Text>
      <Text className={styles.text}>{text}</Text>
      <Button
        danger
        type='text'
        className={styles.undo}
        onClick={handleClick}
      >
        {buttonText}
      </Button>
    </div>
  )
}