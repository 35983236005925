import { createAction } from '@reduxjs/toolkit'
import {
  PrepareSetSearchText,
  PrepareSetSearchSelectedMatchId,
  PrepareSetSearchMode,
  PrepareSetReplaceSearchText,
  PrepareSetReplaceText,
  PrepareReplaceText,
  ReplaceTextPayload, PrepareSetValidationError
} from 'redux/actions/transcript-search/types'
import {
  SET_SEARCH_TEXT,
  SET_SEARCH_SELECTED_MATCH_ID,
  SET_SEARCH_MODE,
  SET_REPLACE_SEARCH_TEXT,
  SET_REPLACE_TEXT,
  REPLACE_TEXT,
  RESET_STATE, SET_VALIDATION_ERROR
} from 'redux/actions/transcript-search/constants'
import { TranscriptSearchMode } from 'redux/reducers/transcript-search/types'

export const setSearchText = createAction<PrepareSetSearchText>(
  SET_SEARCH_TEXT,
  (text: string) => ({
    payload: { text }
  }))

export const setSearchSelectedMatchId = createAction<PrepareSetSearchSelectedMatchId>(
  SET_SEARCH_SELECTED_MATCH_ID,
  (id: string | null) => ({
    payload: { id }
  }))

export const setSearchMode = createAction<PrepareSetSearchMode>(SET_SEARCH_MODE, (value: TranscriptSearchMode | null) => ({
  payload: { value }
}))

export const setReplaceSearchText = createAction<PrepareSetReplaceSearchText>(SET_REPLACE_SEARCH_TEXT, (text: string) => ({
  payload: { text }
}))

export const setReplaceText = createAction<PrepareSetReplaceText>(SET_REPLACE_TEXT, (text: string) => ({
  payload: { text }
}))

export const replaceText = createAction<PrepareReplaceText>(REPLACE_TEXT, ({ replaceAll }: ReplaceTextPayload = {}) => ({
  payload: { replaceAll }
}))

export const setValidationError = createAction<PrepareSetValidationError>(SET_VALIDATION_ERROR, (text: string | null) => ({
  payload: { text }
}))

export const resetState = createAction(RESET_STATE)