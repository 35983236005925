import { all, delay, takeLatest, race, call, take, put } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { ConsumeSystemMessagesPayload, StartPollingSystemMessagesPayload } from 'redux/actions/system-messages/types'
import { NotificationType } from 'redux/reducers/notifications/types'
import {
  CLEAR_SYSTEM_MESSAGES,
  CONSUME_SYSTEM_MESSAGES,
  START_POLLING_SYSTEM_MESSAGES,
  STOP_POLLING_SYSTEM_MESSAGES
} from 'redux/actions/system-messages/constants'
import { POLLING_INTERVAL } from 'redux/sagas/system-messages/constants'
import api from 'api'
import { intl } from 'utils/common/intl'
import { clearSystemMessages, consumeSystemMessages } from 'redux/actions/system-messages'
import { stopPollingTranscription } from 'redux/actions/audio-pipeline'
import { addNotification, clearNotifications } from 'redux/actions/notifications'
import { filterErrorsMessages, mapSystemMessages } from 'utils/audio-pipeline/mappers/system-messages'

function* handleConsumeSystemMessages({ payload: { data } }: PayloadAction<ConsumeSystemMessagesPayload>) {
  const errors = filterErrorsMessages(data)

  for (let error of errors) {
    const { id, status } = error
    const defaultMessage = intl.formatMessage({ id: `component.error.default.message` })
    const message = intl.formatMessage({
      id: `component.error.${status}.message`,
      defaultMessage
    })

    yield put(addNotification({
      id,
      type: NotificationType.Error,
      message,
      duration: 0
    }))
  }
}

function* handleClearSystemMessages() {
  yield put(clearNotifications())
}

function* handleFetchMessages(id: string) {
  try {
    const { data } = yield call(api.getTranscriptionMessages, {
      urlParams: { id }
    })

    const mappedMessages = data?.length ? mapSystemMessages(data) : []
    const errors = filterErrorsMessages(mappedMessages)

    if (errors?.length) {
      yield put(consumeSystemMessages(mappedMessages))
      yield put(stopPollingTranscription())
    }
  } catch (err) {
    console.error(err)
  }
}

function* handlePollingMessages(id: string) {
  while (true) {
    yield call(handleFetchMessages, id)
    yield delay(POLLING_INTERVAL)
  }
}

const handleStartPollingMessages = function* ({ payload: { id } }: PayloadAction<StartPollingSystemMessagesPayload>) {
  yield put(clearSystemMessages())

  yield race({
    polling: call(handlePollingMessages, id),
    cancel: take(STOP_POLLING_SYSTEM_MESSAGES)
  })
}

export function* messages() {
  yield all([
    takeLatest(START_POLLING_SYSTEM_MESSAGES, handleStartPollingMessages),
    takeLatest(CONSUME_SYSTEM_MESSAGES, handleConsumeSystemMessages),
    takeLatest(CLEAR_SYSTEM_MESSAGES, handleClearSystemMessages)
  ])
}