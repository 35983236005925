import { createAction } from '@reduxjs/toolkit'
import {
  START_POLLING_SYSTEM_MESSAGES,
  STOP_POLLING_SYSTEM_MESSAGES,
  CONSUME_SYSTEM_MESSAGES,
  CLEAR_SYSTEM_MESSAGES
} from 'redux/actions/system-messages/constants'
import { PrepareConsumeSystemMessages, PrepareStartPollingSystemMessages } from 'redux/actions/system-messages/types'
import { SystemMessage } from 'redux/reducers/system-messages/types'

export const startPollingSystemMessages = createAction<PrepareStartPollingSystemMessages>(START_POLLING_SYSTEM_MESSAGES, (id: string) => ({
  payload: { id }
}))
export const stopPollingSystemMessages = createAction(STOP_POLLING_SYSTEM_MESSAGES)

export const consumeSystemMessages = createAction<PrepareConsumeSystemMessages>(CONSUME_SYSTEM_MESSAGES, (data: SystemMessage[]) => ({
  payload: {
    data
  }
}))

export const clearSystemMessages = createAction(CLEAR_SYSTEM_MESSAGES)