import React from 'react'
import { AudioPipeline } from 'components/audio-pipeline'
import { Header } from 'components/layouts/common/Header'

const Index: React.FC = () => (
  <>
    <Header />
    <AudioPipeline />
  </>
)

export default Index
