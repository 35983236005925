import { createAction } from '@reduxjs/toolkit'
import {
  ADD_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
  REMOVE_NOTIFICATION
} from 'redux/actions/notifications/constants'
import { DEFAULT_NOTIFICATION_DURATION } from 'redux/reducers/notifications/constants'
import {
  PrepareAddNotificationActionType,
  PrepareRemoveNotificationActionType
} from 'redux/actions/notifications/types'
import { NotificationPlacement } from 'redux/reducers/notifications/types'

export const addNotification =
  createAction<PrepareAddNotificationActionType>(
    ADD_NOTIFICATION,
    ({
      id,
      type,
      message,
      description,
      placement = NotificationPlacement.BottomRight,
      duration = DEFAULT_NOTIFICATION_DURATION
    }) => ({
      payload: {
        id,
        type,
        message,
        description,
        placement,
        duration
      }
    })
  )

export const removeNotification =
  createAction<PrepareRemoveNotificationActionType>(
    REMOVE_NOTIFICATION,
    (id) => ({
      payload: { id }
    })
  )

export const clearNotifications = createAction(CLEAR_NOTIFICATIONS)
