import React from 'react'
import { Routes } from 'routes/constants'
import { Navigate } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'
import config from 'config'

interface OwnProps {
  children: React.ReactNode
}

export type Props = OwnProps

export const PrivateRoute: React.FC<Props> = ({
  children
}) => {
  const { authState } = useOktaAuth()

  if (config.auth.enabled && !authState?.isAuthenticated) {
    return <Navigate to={Routes.Login} />
  }

  return (
    <>
      {children}
    </>
  )
}
