import React from 'react'
import classNames from 'classnames'
import { SpinSize } from 'antd/es/spin'
import { DEFAULT_SPIN_SIZE } from 'components/common/feedback/Loader/constants'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import styles from './styles.module.css'

const LoaderIcon = <LoadingOutlined style={{ fontSize: 24 }} spin/>

interface Props {
  className?: string
  size?: SpinSize
}

export const Loader: React.FC<Props> = ({
  className,
  size = DEFAULT_SPIN_SIZE
}) => (
  <Spin
    size={size}
    indicator={LoaderIcon}
    className={classNames(styles.wrapper, className)}
  />
)
