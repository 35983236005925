import { ReducersType } from 'redux/reducers/types'

export const SET_SELECTED_LANGUAGE = `${ReducersType.AudioPipeline}/setSelectedLanguage`
export const SET_FILE_URL = `${ReducersType.AudioPipeline}/setFileUrl`
export const CONSUME_TRANSCRIPTION = `${ReducersType.AudioPipeline}/consumeTranscription`
export const SET_SUMMARISED_TEXT = `${ReducersType.AudioPipeline}/setSummarisedText`
export const SET_HEADLINE_TAILORING_TEXT = `${ReducersType.AudioPipeline}/setHeadlineTailoringText`
export const SET_GENERATED_HEADLINE_TEXT = `${ReducersType.AudioPipeline}/setGeneratedHeadlineText`
export const SET_GENERATED_ARTICLE_TEXT = `${ReducersType.AudioPipeline}/setGeneratedArticleText`
export const SET_SEO_TAGS_TEXT = `${ReducersType.AudioPipeline}/setSEOTagsText`
export const SET_SPEAKER_NAMES_FIELD_TEXT = `${ReducersType.AudioPipeline}/setSpeakerNamesFieldText`
export const TRANSCRIBE = `${ReducersType.AudioPipeline}/transcribe`
export const SUMMARISE = `${ReducersType.AudioPipeline}/summarise`
export const GENERATE_ARTICLE = `${ReducersType.AudioPipeline}/generateArticle`
export const GENERATE_SEO_TAGS = `${ReducersType.AudioPipeline}/generateSEOTags`
export const GENERATE_HEADLINE = `${ReducersType.AudioPipeline}/generateHeadline`
export const START_POLLING_TRANSCRIPTION = `${ReducersType.AudioPipeline}/startPollingTranscription`
export const STOP_POLLING_TRANSCRIPTION = `${ReducersType.AudioPipeline}/stopPollingTranscription`
export const UPLOAD_FILE = `${ReducersType.AudioPipeline}/uploadFile`
export const SET_FILE_UPLOAD_PROGRESS = `${ReducersType.AudioPipeline}/setFileUploadProgress`
export const SET_SELECTED_FILE = `${ReducersType.AudioPipeline}/setSelectedFile`
export const CLEAR_INPUT_FIELDS = `${ReducersType.AudioPipeline}/clearInputFields`
export const SET_PLAYER_CURRENT_TIME = `${ReducersType.AudioPipeline}/setPlayerCurrentTime`
export const SET_PLAYER_SELECTED_WORD_ID = `${ReducersType.AudioPipeline}/setPlayerSelectedWordId`
export const START_PLAYBACK = `${ReducersType.AudioPipeline}/startPlayback`
export const STOP_PLAYBACK = `${ReducersType.AudioPipeline}/stopPlayback`
export const START_EDIT_WORD = `${ReducersType.AudioPipeline}/startEditWord`
export const STOP_EDIT_WORD = `${ReducersType.AudioPipeline}/stopEditWord`
export const SET_EDITED_WORD_ID = `${ReducersType.AudioPipeline}/setEditedWordId`
export const SET_EDITED_WORD_TEXT = `${ReducersType.AudioPipeline}/setEditedWordText`
export const SET_EDITED_WORD = `${ReducersType.AudioPipeline}/setEditedWord`
export const REVERT_EDITED_WORD = `${ReducersType.AudioPipeline}/revertEditedWord`
export const COPY_TRANSCRIPT_TEXT = `${ReducersType.AudioPipeline}/copyTranscriptText`
export const SET_SPEAKER_NAMES = `${ReducersType.AudioPipeline}/setSpeakerNames`