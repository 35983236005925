import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReducersType } from 'redux/reducers/types'
import { SystemMessagesInitialState } from 'redux/reducers/system-messages/constants'
import { ConsumeSystemMessagesPayload } from 'redux/actions/system-messages/types'

export const systemMessages = createSlice({
  name: ReducersType.SystemMessages,
  initialState: SystemMessagesInitialState,
  reducers: {
    consumeMessages: (
      state,
      { payload: { data } }: PayloadAction<ConsumeSystemMessagesPayload>
    ) => ({
      ...state,
      items: [...state.items, ...data]
    }),
    clearMessages: (state) => {
      state.items = SystemMessagesInitialState.items
    },
  }
})
