import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { Typography } from 'antd'
import { TranscriptWord } from 'components/audio-pipeline/TranscribedText/Transcript/TranscriptWord'
import { TranscriptionWord } from 'components/audio-pipeline/TranscribedText/Transcript/types'
import {
  getPlayerCurrentTime,
  getSpeakerNames
} from 'redux/selectors/audio-pipeline'
import { replaceSpeakerNames } from 'utils/audio-pipeline'
import styles from './styles.module.css'

const { Text, Paragraph } = Typography

interface Props {
  speaker: string | null
  index: number
  startTime: number
  endTime: number
  words: TranscriptionWord[]
  onParagraphActive: (index: number) => void
}

export const SpeakerParagraph: React.FC<Props> = ({
  speaker,
  index,
  words,
  startTime,
  endTime,
  onParagraphActive
}) => {
  const playerCurrentTime = useSelector(getPlayerCurrentTime)
  const speakerNames = useSelector(getSpeakerNames)
  const name = speaker
    ? replaceSpeakerNames({ text: speaker, names: speakerNames })
    : null
  const currentTimeInMs = playerCurrentTime * 1000
  const isParagraphActive = currentTimeInMs >= startTime && currentTimeInMs <= endTime

  useEffect(() => {
    if (isParagraphActive) {
      onParagraphActive(index)
    }
  }, [isParagraphActive, index, onParagraphActive])

  const renderWord = useCallback(({ id, text, startTime, endTime }: TranscriptionWord) => {
    const isPassed = currentTimeInMs >= startTime
    const isActive = startTime <= currentTimeInMs && currentTimeInMs <= endTime

    return (
      <TranscriptWord
        id={id}
        key={id}
        text={text}
        isPassed={isPassed}
        isActive={isActive}
        startTime={startTime}
        endTime={endTime}
      />
    )
  }, [currentTimeInMs])

  return (
    <div className={classNames({
      [styles.monologStart]: !!speaker && index !== 0
    })}>
      {name && <Text className={styles.speaker}>{name}</Text>}
      <Paragraph className={styles.text}>{words.map(renderWord)}</Paragraph>
    </div>
  )
}