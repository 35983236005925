import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import { Button } from 'components/common/general/Button'
import { Componentify } from 'components/common/other/Componentify'
import styles from './styles.module.css'

interface Props {
  className?: string
}

export const DropZoneDescription: React.FC<Props> = ({
  className
}) => {
  const { formatMessage } = useIntl()
  const browseFilesButtonText = formatMessage({
    id: 'component.audio-pipeline.file-upload.drop-zone.browse-btn-text'
  })
  const description = formatMessage({
    id: 'component.audio-pipeline.file-upload.drop-zone.description'
  })
  const patterns = useMemo(() => [browseFilesButtonText], [browseFilesButtonText])

  const handleMatch = useCallback(({
    text,
    index
  }: {
    text: string,
    index: number
  }) => (
    <Button
      key={`${text}-${index}`}
      text={text}
      type='text'
      className={styles.browseButton}
    />
  ), [])

  return (
    <Componentify
      text={description}
      patterns={patterns}
      className={classNames(styles.wrapper, className)}
      onMatch={handleMatch}
    />
  )
}