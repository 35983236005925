import React from 'react'
import styles from './styles.module.css'

interface Props {
  children: React.ReactNode
}

export const Section: React.FC<Props> = ({
  children
}) => (
  <div className={styles.wrapper}>{children}</div>
)