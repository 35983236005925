import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Title } from 'components/common/general/Title'
import { CopyOutlined } from '@ant-design/icons'
import { TranscriptionSearchBar } from 'components/audio-pipeline/TranscribedText/Transcript/SearchBar'
import { getFormattedTranscribedText } from 'redux/selectors/audio-pipeline'
import { copyTranscriptText } from 'redux/actions/audio-pipeline'
import styles from './styles.module.css'

export const TranscriptSectionHeader: React.FC = () => {
  const dispatch = useDispatch()
  const text = useSelector(getFormattedTranscribedText)
  const { formatMessage } = useIntl()
  const title = formatMessage({
    id: 'component.audio-pipeline.transcribed-text.title'
  })

  const handleCopyToClipboard = useCallback(() => {
    dispatch(copyTranscriptText())
  }, [dispatch])

  return (
    <div className={styles.wrapper}>
      <Title text={title} />
      <TranscriptionSearchBar />
      {text && (
        <CopyOutlined
          className={styles.copy}
          onClick={handleCopyToClipboard}
        />
      )}
    </div>
  )
}