import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import config from 'config'
import styles from './styles.module.css'

export const HelpLink = () => {
  const { formatMessage } = useIntl()
  const helpLink = formatMessage({
    id: 'component.common.help-link.text'
  })

  return (
    <Link className={styles.helpText} to={config.links.newsTranscribeGuide} target="_blank">
      {helpLink}
    </Link>
  )
}