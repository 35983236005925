export enum Colors {
  white = '#ffffff',
  black = '#000000',
  athensGray = '#f1f2f5',
  zircon = '#ECF1FF',
  gray = '#919191',
  doveGray = '#6A6A6A',
  hawkesBlue = '#D5E0FC',
  ceruleanBlue = '#3358CC',
  chathamsBlue = '#12387A',
  pattenBlue = '#D1E2FF'
}
