const messages = {
  'component.audio-pipeline.file-url-input.placeholder': 'Enter youtube video url',
  'component.audio-pipeline.transcribe.button': 'Transcribe',
  'component.audio-pipeline.transcribe-cancel.button': 'Cancel',
  'component.audio-pipeline.transcribe-processing.text': 'Processing...',
  'component.audio-pipeline.transcribe-uploading.text': 'Uploading...',
  'component.audio-pipeline.transcribed-text.title': 'Transcribed text',
  'component.audio-pipeline.transcribed-text.description': 'The transcribed text will after once you have uploaded the filed and clicked "transcribe"',
  'component.audio-pipeline.transcribed-text.submit': 'Regenerate',
  'component.audio-pipeline.speaker-names.title': 'Speaker names',
  'component.audio-pipeline.speaker-names.description': 'Separate with comma',
  'component.audio-pipeline.speaker-names.button': 'Replace',
  'component.audio-pipeline.summarised-text.title': 'Summarised text',
  'component.audio-pipeline.summarised-text.submit': 'Summarise text',
  'component.audio-pipeline.summarised-text.resubmit': 'Resummarise text',
  'component.audio-pipeline.headline-tailoring.title': 'Headline tailoring',
  'component.audio-pipeline.headline-tailoring.description': 'Consider using descriptive words in your prompt',
  'component.audio-pipeline.headline-tailoring.placeholder': 'EG: \'Be lighthearted/funny/serious\'. \'Use the word ___\', "Use alliteration"',
  'component.audio-pipeline.generate-headline.title': 'Generated headlines',
  'component.audio-pipeline.generate-headline.description': 'Once you have uploaded an audio file, press the “Transcribe” button to translate',
  'component.audio-pipeline.generate-headline.submit': 'Generate headlines',
  'component.audio-pipeline.generate-headline.resubmit': 'Regenerate headlines',
  'component.audio-pipeline.article-generation.title': 'Article generation',
  'component.audio-pipeline.article-generation.submit': 'Generate article',
  'component.audio-pipeline.article-generation.resubmit': 'Regenerate article',
  'component.audio-pipeline.seo-tags.title': 'SEO Tags',
  'component.audio-pipeline.seo-tags.submit': 'Generate SEO Tags',
  'component.audio-pipeline.seo-tags.resubmit': 'Regenerate SEO Tags',
  'component.audio-pipeline.language-select.option.en': 'English',
  'component.audio-pipeline.language-select.option.en_US': 'American English',
  'component.audio-pipeline.language-select.option.direct': 'Direct transcription',
  'component.audio-pipeline.language-select.label': 'Language',
  'component.audio-pipeline.file-upload.title': 'Upload file',
  'component.audio-pipeline.file-upload.submit': 'Upload',
  'component.audio-pipeline.file-upload.drop-zone.description': 'Drag and drop files here or browse files to transcribe',
  'component.audio-pipeline.file-upload.drop-zone.browse-btn-text': 'browse files',
  'component.audio-pipeline.transcript.edit': 'Edit',
  'component.audio-pipeline.transcript.edit.done': 'Done',
  'component.audio-pipeline.transcript.edit.undo': 'Undo',
  'component.audio-pipeline.transcript.edit.initial': 'Initial:',
}

export default messages
