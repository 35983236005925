import { SystemMessage, RawSystemMessage } from 'redux/reducers/system-messages/types'

export const filterErrorsMessages = (data: SystemMessage[]): SystemMessage[] =>
  data.filter(({ status }) => status >= 400)

export const mapSystemMessages = (data: RawSystemMessage[]): SystemMessage[] => data.map(({
  request_id,
  status,
  message
}) => ({
  id: request_id,
  status,
  message
}))