import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { NotificationItemType } from 'redux/reducers/notifications/types'
import { Notification } from 'components/notification/Notification'
import { getNotifications } from 'redux/selectors/notifications'

export const Notifications: React.FC = () => {
  const notifications = useSelector(getNotifications)

  const renderNotification = useCallback(
    ({
      id,
      type,
      message,
      description,
      placement,
      duration
    }: NotificationItemType, index: number) => (
      <Notification
        id={id}
        key={`${id}-${index}`}
        type={type}
        message={message}
        description={description}
        placement={placement}
        duration={duration}
      />
    ),
    []
  )

  return <>{notifications.map(renderNotification)}</>
}
