import React from 'react'
import classNames from 'classnames'
import NewsTranscribeLogo from 'assets/img/news-transcribe-logo.svg'
import styles from './styles.module.css'

interface Props {
  className?: string
}

export const Logo: React.FC<Props> = ({
  className
}) => (
  <img
    src={NewsTranscribeLogo}
    className={classNames(styles.wrapper, className)}
    alt='news-ai-assist-logo'
  />
)