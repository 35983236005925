import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Input } from 'antd'
import { TranscriptReplaceOptions } from 'components/audio-pipeline/TranscribedText/Transcript/SearchBar/SearchBarContent/ReplaceField/ReplaceOptions'
import { getTranscriptionReplaceText } from 'redux/selectors/transcript-search'
import { setReplaceText } from 'redux/actions/transcript-search'
import styles from './styles.module.css'

export const TranscriptReplaceField: React.FC = () => {
  const dispatch = useDispatch()
  const replaceTextValue = useSelector(getTranscriptionReplaceText)
  const { formatMessage } = useIntl()

  const placeholder = formatMessage({
    id: 'component.transcript-search.search-and-replace.label'
  })

  const handleReplaceTextChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setReplaceText(e.target.value))
  }, [dispatch])

  return (
    <div className={styles.wrapper}>
      <Input
        allowClear
        value={replaceTextValue}
        placeholder={placeholder}
        className={styles.input}
        onChange={handleReplaceTextChange}
      />
      <TranscriptReplaceOptions />
    </div>
  )
}