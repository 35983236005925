import React, { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Typography } from 'antd'
import { LogoutButton } from 'components/common/auth/LogoutButton'
import { Componentify } from 'components/common/other/Componentify'
import { HelpLink } from 'components/layouts/common/Header/HelpLink'
import { useOktaAuth } from '@okta/okta-react'
import styles from './styles.module.css'

const { Text } = Typography

export const UserInfo: React.FC = () => {
  const { authState } = useOktaAuth()
  const { formatMessage } = useIntl()
  const patterns = useMemo(() =>
      authState?.idToken?.claims?.email
        ? [authState?.idToken?.claims?.email]
        : [],
    [authState?.idToken]
  )

  const handleMatch = useCallback(({ text, index }: any) => (
    <Text
      key={`${text}-${index}`}
      className={styles.highlighted}
    >
      {text}
    </Text>
  ), [])

  if (!authState?.idToken) {
    return null
  }

  const { email } = authState.idToken.claims
  const message = formatMessage({
    id: `component.common.user-greeting.text`
  }, { email })

  return (
    <div className={styles.wrapper}>
      <Componentify
        text={message}
        patterns={patterns}
        onMatch={handleMatch}
      />
      <div className={styles.helpSection}>
        <HelpLink />
        <LogoutButton />
      </div>
    </div>
  )
}