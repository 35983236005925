import { RootState } from 'redux/types/store'
import { TranscriptionSearchMatchGroup, TranscriptSearchMode } from 'redux/reducers/transcript-search/types'
import { createSelector } from 'reselect'
import { getEditedWords, getTranscriptionParagraphs, getTranscriptionWords } from 'redux/selectors/audio-pipeline'
import { getSearchTextMatches } from 'redux/selectors/transcript-search/utils'
import { constrain } from 'utils/common/other'

export const getTranscriptionSearchText = (state: RootState) => state.transcriptSearch.text
export const getTranscriptionSearchSelectedMatchId = (state: RootState) => state.transcriptSearch.selectedMatchId
export const getTranscriptionSearchMode = (state: RootState) => state.transcriptSearch.mode
export const getTranscriptionReplaceSearchText = (state: RootState) => state.transcriptSearch.replaceSearchText
export const getTranscriptionReplaceText = (state: RootState) => state.transcriptSearch.replaceText
export const getTranscriptionSearchValidationError = (state: RootState) => state.transcriptSearch.error

export const getSearchText = createSelector(
  getTranscriptionSearchMode,
  getTranscriptionSearchText,
  getTranscriptionReplaceSearchText,
  (mode, searchText, replaceSearchText) =>
    mode === TranscriptSearchMode.Search
      ? searchText
      : replaceSearchText
)

export const getSearchWordMatches = createSelector(
  getSearchText,
  getTranscriptionWords,
  getEditedWords,
  (searchText, words, editedWordsById) => {
    if (!searchText) {
      return []
    }

    return getSearchTextMatches({ words, searchText })
  }
)

export const getTranscriptionSearchMatchedWordIds = createSelector(
  getSearchWordMatches,
  (words) => words.map(({ id }) => id)
)

export const getTranscriptionSearchMatchGroupsById = createSelector(
  getSearchWordMatches,
  words =>
    words
      .reduce((acc: Record<string, TranscriptionSearchMatchGroup>, word) => {
        const { groupId } = word

        if (!acc[groupId]) {
          acc[groupId] = {
            id: groupId,
            words: []
          }
        }

        acc[groupId].words.push(word)

        return acc
      }, {} as Record<string, TranscriptionSearchMatchGroup>)
)

export const getTranscriptionSearchMatchGroupIds = createSelector(
  getTranscriptionSearchMatchGroupsById,
  groups =>
    Object
      .values(groups)
      .map(({ id }) => id)
)

export const getSearchSelectedMatchGroupWords = createSelector(
  getTranscriptionSearchSelectedMatchId,
  getTranscriptionSearchMatchGroupsById,
  (selectedGroupId, matchesById) => {
    const group = selectedGroupId && matchesById[selectedGroupId]

    if (!group) {
      return []
    }

    return group.words
  }
)

export const getSearchSelectedMatchGroupWordIds = createSelector(
  getSearchSelectedMatchGroupWords,
  (words) => words.map(({ id }) => id)
)

export const getSearchSelectedMatchStartTime = createSelector(
  getSearchSelectedMatchGroupWords,
  (words) => {
    const [firstWord] = words

    return firstWord?.startTime
  }
)

export const getSearchSelectedMatchParagraphIndex = createSelector(
  getTranscriptionParagraphs,
  getSearchSelectedMatchStartTime,
  (paragraphs, matchStartTime) => {
    const paragraph = paragraphs.find(({
      startTime,
      endTime
    }) => matchStartTime >= startTime && matchStartTime <= endTime)

    if (paragraph) {
      return paragraphs.indexOf(paragraph)
    }

    return null
  }
)

export const getMaxSelectedSearchMatchIndex = createSelector(
  getTranscriptionSearchMatchGroupIds,
  (matchGroupIds) => constrain({
    min: 0,
    max: matchGroupIds.length,
    value: matchGroupIds.length - 1
  })
)

export const getSelectedSearchMatchIndex = createSelector(
  getTranscriptionSearchMatchGroupIds,
  getTranscriptionSearchSelectedMatchId,
  getMaxSelectedSearchMatchIndex,
  (matchGroupIds, selectedMatchId, maxSelectedIndex) =>
    constrain({
      min: 0,
      max: maxSelectedIndex,
      value: selectedMatchId ? matchGroupIds.indexOf(selectedMatchId) : 0
    })
)