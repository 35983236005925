import React, { useCallback, } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { setSpeakerNamesFieldText, setSpeakerNames} from 'redux/actions/audio-pipeline'
import { TextArea } from 'components/common/data-entry/TextArea'
import { getSpeakerNamesText } from 'redux/selectors/audio-pipeline'

export const SpeakerNames: React.FC = () => {
  const dispatch = useDispatch()
  const speakerNames = useSelector(getSpeakerNamesText)
  const { formatMessage } = useIntl()
  
  const title = formatMessage({
    id: 'component.audio-pipeline.speaker-names.title'
  })
  const description = formatMessage({
    id: 'component.audio-pipeline.speaker-names.description'
  })
  const submitText = formatMessage({
    id: 'component.audio-pipeline.speaker-names.button'
  })

  const handleChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setSpeakerNamesFieldText(e.target.value))
  }, [dispatch])

  const handleSubmit = useCallback(() => {
    dispatch(setSpeakerNames(speakerNames.split(',').map(value => value.trim())))
  }, [speakerNames, dispatch])

  return (
    <TextArea
      rows={1}
      title={title}
      description={description}
      value={speakerNames}
      shouldRenderSubmitButton
      submitButtonText={submitText}
      onChange={handleChange}
      onSubmit={handleSubmit}
    />
  )
}