import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReducersType } from 'redux/reducers/types'
import { NotificationItemType } from 'redux/reducers/notifications/types'
import { NotificationsInitialState } from 'redux/reducers/notifications/constants'
import { RemoveNotificationPayloadType } from 'redux/actions/notifications/types'

export const notifications = createSlice({
  name: ReducersType.Notifications,
  initialState: NotificationsInitialState,
  reducers: {
    addNotification: (
      state,
      { payload }: PayloadAction<NotificationItemType>
    ) => ({
      ...state,
      items: [...state.items, payload]
    }),
    removeNotification: (
      state,
      { payload: { id } }: PayloadAction<RemoveNotificationPayloadType>
    ) => ({
      ...state,
      items: state.items.filter(
        (notification: NotificationItemType) => notification.id !== id
      )
    }),
    clearNotifications: (
      state
    ) => {
      state.items = NotificationsInitialState.items
    }
  }
})
