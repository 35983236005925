import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReducersType } from 'redux/reducers/types'
import { TranscriptSearchInitialState } from 'redux/reducers/transcript-search/constants'
import {
  SetSearchModePayload,
  SetSearchSelectedMatchIdPayload,
  SetTextPayload
} from 'redux/actions/transcript-search/types'

export const transcriptSearch = createSlice({
  name: ReducersType.TranscriptSearch,
  initialState: TranscriptSearchInitialState,
  reducers: {
    setSearchText: (state, { payload: { text } }: PayloadAction<SetTextPayload>) => {
      state.text = text
    },
    setSearchSelectedMatchId: (state, { payload: { id } }: PayloadAction<SetSearchSelectedMatchIdPayload>) => {
      state.selectedMatchId = id
    },
    setSearchMode: (state, { payload: { value } }: PayloadAction<SetSearchModePayload>) => {
      state.mode = value
    },
    setReplaceSearchText: (state, { payload: { text } }: PayloadAction<SetTextPayload>) => {
      state.replaceSearchText = text
    },
    setReplaceText: (state, { payload: { text } }: PayloadAction<SetTextPayload>) => {
      state.replaceText = text
    },
    setValidationError: (state, { payload: { text } }: PayloadAction<SetTextPayload>) => {
      state.error = text
    },
    resetState: () => TranscriptSearchInitialState
  }
})