import React from 'react'
import { ConfigProvider } from 'antd'
import { RouterProvider } from 'react-router-dom'
import { router } from 'routes/routes'
import { Notifications } from 'components/notification/Notifications'

const App: React.FC = () => (
  <ConfigProvider>
    <Notifications />
    <RouterProvider router={router} />
  </ConfigProvider>
)

export default App
