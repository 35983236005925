import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/types/store'
import { Loaders } from 'redux/reducers/loaders/types'
import { PipelineLoader } from 'components/common/feedback/PipelineLoader'
import { UploadProgress } from 'components/common/data-entry/FileUploadDropZone/DropZone/UploadProgress'
import { getFileUploadProgress, getSelectedFile } from 'redux/selectors/audio-pipeline'
import { getIsLoading } from 'redux/selectors/loaders'
import styles from './styles.module.css'

export const Status: React.FC = () => {
  const { formatMessage } = useIntl()
  const file = useSelector(getSelectedFile)
  const isUploading = useSelector((state: RootState) => getIsLoading(state, Loaders.UploadFile))
  const isTranscribing = useSelector((state: RootState) => getIsLoading(state, Loaders.TranscriptionData))
  const fileUploadProgress = useSelector(getFileUploadProgress)
  const formattedProgress = Math.ceil(fileUploadProgress * 100)
  const loaderLabel = formatMessage({
    id: 'component.audio-pipeline.transcribe-processing.text'
  })
  const isProcessing = file && isTranscribing

  return (
    <div className={styles.wrapper}>
      {fileUploadProgress && isUploading
        ? <UploadProgress percent={formattedProgress} />
        : null
      }
      {isProcessing && <PipelineLoader label={loaderLabel} />}
    </div>
  )
}