import { all, takeLatest, call } from 'redux-saga/effects'
import { LOGIN } from 'redux/actions/auth/constants'
import { oktaAuth } from 'utils/auth/auth-client'

const handleLogin = function* () {
  try {
    yield call([oktaAuth, oktaAuth.signInWithRedirect])
  } catch (err) {
    console.error(err)
  }
}

export function* auth() {
  yield all([
    takeLatest(LOGIN, handleLogin)
  ])
}