import React from 'react'
import { Typography } from 'antd'
import { ClockCircleOutlined } from '@ant-design/icons'
import styles from './styles.module.css'

const { Text } = Typography

interface Props {
  text: string
}

export const WordTooltipTitle: React.FC<Props> = ({
  text
}) => (
  <div className={styles.wrapper}>
    <ClockCircleOutlined className={styles.icon} />
    <Text className={styles.text}>{text}</Text>
  </div>
)