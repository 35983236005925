import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Section } from 'components/common/data-display/Section'
import { TextArea } from 'components/common/data-entry/TextArea'
import { generateHeadline, setHeadlineTailoringText } from 'redux/actions/audio-pipeline'
import {
  getFormattedTranscribedText,
  getGeneratedHeadlineText,
  getHeadlineTailoringText
} from 'redux/selectors/audio-pipeline'
import { RootState } from 'redux/types/store'
import { getIsLoading } from 'redux/selectors/loaders'
import { Loaders } from 'redux/reducers/loaders/types'

export const Headline: React.FC = () => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const headlineTailoringText = useSelector(getHeadlineTailoringText)
  const generatedHeadlineText = useSelector(getGeneratedHeadlineText)
  const transcribedText = useSelector(getFormattedTranscribedText)
  const isLoading = useSelector((state: RootState) => getIsLoading(state, Loaders.GenerateHeadline))

  const title = formatMessage({
    id: 'component.audio-pipeline.headline-tailoring.title'
  })
  const description = formatMessage({
    id: 'component.audio-pipeline.headline-tailoring.description'
  })
  const headlineTailoringPlaceholder = formatMessage({
    id: 'component.audio-pipeline.headline-tailoring.placeholder'
  })
  const generateHeadlineTitle = formatMessage({
    id: 'component.audio-pipeline.generate-headline.title'
  })
  const submitText = formatMessage({
    id: 'component.audio-pipeline.generate-headline.submit'
  })
  const reSubmitText = formatMessage({
    id: 'component.audio-pipeline.generate-headline.resubmit'
  })

  const buttonText = generatedHeadlineText ? reSubmitText : submitText

  const handleHeadlineTailoringChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setHeadlineTailoringText(e.target.value))
  }, [dispatch])

  const handleSubmit = useCallback(() => {
    dispatch(generateHeadline())
  }, [dispatch])

  return (
    <Section>
      <TextArea
        title={title}
        rows={1}
        description={description}
        placeholder={headlineTailoringPlaceholder}
        value={headlineTailoringText}
        onChange={handleHeadlineTailoringChange}
      />
      <TextArea
        title={generateHeadlineTitle}
        value={generatedHeadlineText}
        shouldRenderSubmitButton
        isSubmitDisabled={!transcribedText}
        isLoading={isLoading}
        submitButtonText={buttonText}
        onSubmit={handleSubmit}
      />
    </Section>
  )
}