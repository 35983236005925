import React, { useCallback } from 'react'
import { Button } from 'components/common/general/Button'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import {
  getTranscriptionReplaceSearchText,
  getTranscriptionReplaceText,
  getTranscriptionSearchMatchGroupIds, getTranscriptionSearchValidationError
} from 'redux/selectors/transcript-search'
import { replaceText } from 'redux/actions/transcript-search'
import styles from './styles.module.css'

export const TranscriptReplaceOptions: React.FC = () => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const replaceSearchTextValue = useSelector(getTranscriptionReplaceSearchText)
  const replaceTextValue = useSelector(getTranscriptionReplaceText)
  const matchGroupIds: string[] = useSelector(getTranscriptionSearchMatchGroupIds)
  const error = useSelector(getTranscriptionSearchValidationError)
  const isReplaceDisabled = !replaceSearchTextValue
    || !replaceTextValue
    || !matchGroupIds.length
    || replaceSearchTextValue === replaceTextValue
    || !!error

  const replaceButtonLabel = formatMessage({
    id: 'component.transcript-search.search-and-replace.label'
  })

  const replaceAllButtonLabel = formatMessage({
    id: 'component.transcript-search.search-and-replace.replace-all'
  })

  const handleReplace = useCallback(() => {
    dispatch(replaceText())
  }, [dispatch])

  const handleReplaceAll = useCallback(() => {
    dispatch(replaceText({ replaceAll: true }))
  }, [dispatch])

  return (
    <div className={styles.wrapper}>
      <Button
        text={replaceButtonLabel}
        className={styles.button}
        disabled={isReplaceDisabled}
        onClick={handleReplace}
      />
      <Button
        text={replaceAllButtonLabel}
        className={styles.button}
        disabled={isReplaceDisabled}
        onClick={handleReplaceAll}
      />
    </div>
  )
}