import React, { useCallback, useRef } from 'react'
import { FileDropZone } from 'components/common/data-entry/FileUploadDropZone/DropZone'
import styles from './styles.module.css'

interface Props {
  file?: File | null
  onSelectFile: (file: File | null) => void
  onRemoveFile?: () => void
}

export const FileUploadDropZone: React.FC<Props> = ({
  file,
  onSelectFile,
  onRemoveFile
}) => {
  const inputRef = useRef<any>(null)

  const handleSelectFile = useCallback((file: File | null) => {
    inputRef.current.value = ''

    onSelectFile(file)
  }, [onSelectFile])

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]

    if (file) {
      handleSelectFile(file)
    }
  }, [handleSelectFile])

  const handleDropZoneClick = useCallback(() => {
    if (inputRef.current) {
      // @ts-ignore
      inputRef.current.click()
    }
  }, [inputRef])

  return (
    <>
      <input
        ref={inputRef}
        className={styles.input}
        type='file'
        accept='audio/*'
        onChange={handleChange}
      />
      <FileDropZone
        fileName={file?.name}
        onDrop={handleSelectFile}
        onClick={handleDropZoneClick}
        onRemoveFile={onRemoveFile}
      />
    </>
  )
}