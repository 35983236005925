import React from 'react'
import { Section } from 'components/common/data-display/Section'
import { Transcript } from 'components/audio-pipeline/TranscribedText/Transcript'
import { SpeakerNames } from 'components/audio-pipeline/SpeakerNames'

export const TranscribedText: React.FC = () => (
  <Section>
    <SpeakerNames />
    <Transcript />
  </Section>
)