import React from 'react'
import { createRoot } from 'react-dom/client'
import messages from 'i18n/en_US'
import store from 'redux/store'
import { Provider } from 'react-redux'
import { IntlProvider } from 'react-intl'
import App from './App'
import 'index.css'

const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
  //@ts-ignore
  <Provider store={store}>
    {/*//@ts-ignore*/}
    <IntlProvider locale="en" defaultLocale="en" messages={messages}>
      {/*//@ts-ignore*/}
      <App />
    </IntlProvider>
  </Provider>
)
