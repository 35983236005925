import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { TranscriptSearchMode } from 'redux/reducers/transcript-search/types'
import { SEARCH_MODE_OPTIONS } from 'components/audio-pipeline/TranscribedText/Transcript/SearchBar/SearchModeSwitch/constants'
import { Radio, RadioChangeEvent } from 'antd'
import { getTranscriptionSearchMode } from 'redux/selectors/transcript-search'
import { setSearchMode } from 'redux/actions/transcript-search'
import styles from './styles.module.css'

export const TranscriptSearchModeSwitch: React.FC = () => {
  const dispatch = useDispatch()
  const mode = useSelector(getTranscriptionSearchMode)
  const { formatMessage } = useIntl()

  const handleChange = useCallback((e: RadioChangeEvent) => {
    dispatch(setSearchMode(e.target.value))
  }, [dispatch])

  const renderOption = useCallback((value: TranscriptSearchMode) => {
    const label = formatMessage({
      id: `component.transcript-search.${value}.label`
    })

    return (
      <Radio.Button
        key={value}
        value={value}
        className={styles.button}
      >
        {label}
      </Radio.Button>
    )
  }, [formatMessage])

  return (
    <Radio.Group
      size='small'
      value={mode}
      onChange={handleChange}
    >
      {SEARCH_MODE_OPTIONS.map(renderOption)}
    </Radio.Group>
  )
}