export const config = {
  dev: {
    api: {
      url: 'http://localhost:8085'
    },
    links: {
      newsTranscribeGuide: 'https://sites.google.com/news.co.uk/newsuk-howtoguides/home',
    },
    auth: {
      enabled: true,
      oidc: {
        issuer: 'https://newscorp.oktapreview.com/oauth2/aus27dvbc31HFdgyN0h8',
        clientId: '0oa1ythmrv7xd7I1l0h8',
        scopes: ['openid', 'email'],
        redirectUri: `http://localhost:8000/user/login`,
        postLogoutRedirectUri: 'http://localhost:8000/user/login'
      }
    },
    uploadEnabled: true
  },
  staging: {
    api: {
      url: 'https://news-transcribe.staging-news.co.uk/'
    },
    links: {
      newsTranscribeGuide: 'https://sites.google.com/news.co.uk/newsuk-howtoguides/home',
    },
    auth: {
      enabled: true,
      oidc: {
        issuer: 'https://newscorp.oktapreview.com/oauth2/aus27s67k5ut0hXlM0h8',
        clientId: '0oa21yeaspuUudxBx0h8',
        scopes: ['openid', 'email'],
        redirectUri: `https://news-transcribe.staging-news.co.uk/user/login`,
        postLogoutRedirectUri: 'https://news-transcribe.staging-news.co.uk/user/login'
      }
    },
    uploadEnabled: true
  },
  prod: {
    api: {
      url: 'https://news-transcribe.news.co.uk/'
    },
    links: {
      newsTranscribeGuide: 'https://sites.google.com/news.co.uk/newsuk-howtoguides/home',
    },
    auth: {
      enabled: true,
      oidc: {
        issuer: 'https://newscorp.okta.com/oauth2/aus178doqpxBezm0q0x8',
        clientId: '0oa144lbo0zTWZMXl0x8',
        scopes: ['openid', 'email'],
        redirectUri: `https://news-transcribe.news.co.uk/user/login`,
        postLogoutRedirectUri: 'https://news-transcribe.news.co.uk/user/login'
      }
    },
    uploadEnabled: true
  }
}