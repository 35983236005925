import React from 'react'
import { Tag } from 'antd'
import {
  CloseCircleOutlined
} from '@ant-design/icons'
import styles from './styles.module.css'

interface Props {
  name: string
  isProcessing?: boolean
  onClose?: () => void
}

export const FileName: React.FC<Props> = ({
  name,
  isProcessing,
  onClose
}) => (
  <Tag
    className={styles.wrapper}
    closeIcon={!isProcessing && <CloseCircleOutlined className={styles.closeIcon} />}
    onClose={onClose}
  >
    {name}
  </Tag>
)