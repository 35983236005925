import React from 'react'
import { useIntl } from 'react-intl'
import { Progress, Typography } from 'antd'
import { Colors } from 'constants/common/other/colors'
import styles from './styles.module.css'

const { Text } = Typography

interface Props {
  percent: number
}

export const UploadProgress: React.FC<Props> = ({
  percent
}) => {
  const { formatMessage } = useIntl()
  const uploadingText = formatMessage({
    id: 'component.audio-pipeline.transcribe-uploading.text'
  })

  return (
    <div className={styles.wrapper}>
      <Text className={styles.label}>{uploadingText}</Text>
      <Progress type='circle' percent={percent} size='small' strokeColor={Colors.ceruleanBlue} />
    </div>
  )
}